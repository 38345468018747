<template>
   <v-slide-x-reverse-transition>
      <div
         v-if="selfItem != null"
         class="sideForm"
         :class="{ sticktop: scrolled }"
      >
         <v-card>
            <v-card-title>
               {{ (!selfItem.id) ? 'Add a new category' : (selfItem.parent_id===0)?'Edit category':'Edit subcategory' }}
            </v-card-title>
            <v-card>
               <v-icon class="closeBTN" @click="closeForm"> mdi-close </v-icon>
            </v-card>
         </v-card>
         <v-divider></v-divider>

         <v-row v-show="!selfItem.id" class="pa-0">
            <v-col class="d-flex justify-center align-center pa-0 pt-5">
               <v-radio-group v-model="isParent" dark row class="">
                  <v-radio color="primary" label="Category" :value="1">
                     <template v-slot:label>
                        <div><strong class="white--text">Category</strong></div>
                     </template>
                  </v-radio>
                  <v-radio color="primary" label="Subcategory" :value="0">
                     <template v-slot:label>
                        <div><strong class="white--text">Sub-Category</strong></div>
                     </template>
                  </v-radio>
               </v-radio-group>
            </v-col>
         </v-row>
         <v-row>
            <v-col>
               <v-form class="pt-0" ref="form" v-model="valid" lazy-validation>
                  <v-color-picker
                     v-if="isParent"
                     width="300"
                     hide-canvas
                     hide-inputs
                     hide-mode-switch
                     hide-sliders
                     mode="hexa"
                     dark
                     show-swatches
                     elevation = "0"
                     swatches-max-height="105"
                     :swatches="[colorSwatches]"
                     v-model="colorPicker"
                     class="pb-5"
                  ></v-color-picker>

                  <v-select
                     v-show="categoryItems && !isParent"
                     :items="get_parent_categories(categoryItems)"
                     v-model="selfItem.parent_id"
                     :menu-props="{ zIndex: 1000 }"
                     item-text="title"
                     item-value="id"
                     persistent-placeholder
                     label="select parent category">
                  </v-select>
                  <v-select v-model="selfItem.icon" v-if="!isParent" :items="icon_items" label="Icon" persistent-placeholder>
                     <template v-slot:selection="{ item }" class="">
                        <v-img
                           :src="getWhiteSVGimageURL(item)"
                           max-height="25"
                           max-width="25"
                           contain
                           class="mr-2"
                        ></v-img>
                        <span
                           class="d-inline-block text-truncate"
                           style="max-width: 180px;"
                        >{{ item }}</span>
                     </template>
                     <template v-slot:item="{  item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                           <v-list-item-icon>
                              <v-img
                                 :src="getWhiteSVGimageURL(item)"
                                 max-height="35"
                                 max-width="35"
                                 contain
                              ></v-img>
                           </v-list-item-icon>
                           <v-list-item-content>
                              <v-list-item-title v-text="item"></v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                     </template>
                  </v-select>
                  <v-text-field
                     v-model="selfItem.title"
                     :rules="nameRules"
                     label="Name"
                     persistent-placeholder
                     required
                  ></v-text-field>

                  <v-card class="formFooterButtons">
                     <v-btn :disabled="!valid" color="info" @click="submit">Apply
                        <v-icon
                           right
                        >
                           mdi-check-bold
                        </v-icon>
                     </v-btn>
                  </v-card>
               </v-form>
            </v-col></v-row>
      </div>
   </v-slide-x-reverse-transition>
</template>

<script>
// TODO: need to add parent selection
// TODO: need to add icon selection from server side

import CategoryService from '@/service/CategoryService';
import UtilsService from '@/service/UtilsService.js';
import Category from '@/models/Category.js';
import Util from '@/utils';

export default {
   name: 'CategoryForm',
   props: {
      item: {
         type: Object,
         default: null,
      },
      categoryItems: {
         type: Array,
         default: null,
      },
   },
   watch: {
      item: function (val) {
         if (val != null) {
            this.selfItem = new Category(val);
            this.isParent = (this.selfItem.parent_id > 0) ? 0 : 1;
         } else {
            this.selfItem = null;
         }
      },
   },
   data: () => ({
      selfItem: null,
      valid: false,
      email: '',
      hex: '#f1f1f1ff',
      expDate: '',
      usersCount: '',
      logo: '',
      address: '',
      name: '',
      colorSwatches: ['#f1f1f1ff', '#A5673Fff', '#E03997ff', '#A333C8ff', '#6435C9ff', '#2185D0ff', '#00B5ADff', '#21BA45ff', '#B5CC18ff', '#FBBD08ff', '#F2711Cff', '#DB2828ff'],
      nameRules: [
         (v) => !!v || 'This field is required',
         (v) => v.length <= 50 || 'Name must be less than 10 characters',
      ],
      emailRules: [
         (v) => !!v || 'This field is required',
         (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      usersCountRules: [
         (v) => !!v || 'This field is required',
         (v) => v >= 1 || 'This field must ne a number',
      ],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      icon_items: [],
      isParent: 1,
      scrolled: false,
   }),
   computed: {
      colorPicker: {
         get() {
            return this.hex;
         },
         set(v) {
            this.selfItem.color = v;
            this.hex = v;
         },
      },
   },
   created() {
      this.callServiceListIcons();
   },
   methods: {
      get_parent_categories(cats) {
         let i = 0;
         const result = [];
         for (i = 0; i < cats.length; i += 1) {
            if (cats[i].parent_id === 0) {
               result.push(cats[i]);
            }
         }
         return result;
      },
      getWhiteSVGimageURL(val) {
         return `${process.env.VUE_APP_API}/svg/f1f1f1ff/${val}.svg`;
      },
      callServiceListIcons() {
         const utilsService = new UtilsService(this);
         utilsService.list_icons().then((list) => {
            if (list) {
               this.icon_items = list.data;
            }
         });
      },
      submit() {
         if (this.isParent === 1) {
            this.selfItem.parent_id = 0;
         }
         if (this.$refs.form.validate()) {
            const categoryService = new CategoryService(this);
            if (!this.selfItem.id) {
               // is this a new item?
               categoryService.add(this.selfItem).then((res) => {
                  if (res.ok) {
                     this.closeForm();
                     //this.$mixpanel.track('Category added ', { res});
                     Util.sendEvent('Category', 'Add', 'Success', { res }); 
                     Util.showMessage('Category added successfuly!');
                  } else {
                     //this.$mixpanel.track('Category add error', { res});
                     Util.sendEvent('Category', 'Add', 'Error', { res });
                     alert('else ...');
                  }
               });
            } else {
               categoryService.update(this.selfItem.id, this.selfItem).then((res) => {
                  if (res.ok) {
                     //this.$mixpanel.track('Category update ', { res});
                     Util.sendEvent('Category', 'Update', 'Success', { res });
                     this.closeForm();
                     Util.showMessage('Category updated successfuly!');
                  } else {
                     //this.$mixpanel.track('Category update error', { res});
                     Util.sendEvent('Category', 'Update', 'Error', { res });
                     alert('else ...');
                  }
               });
            }
         }
      },
      clear() {
         this.$refs.form.reset();
      },
      closeForm() {
         this.selfItem = null;
         this.$emit('update-value', null);
      },
      scrollListener: function () {
         if (window.scrollY > 65) { this.scrolled = true; } else { this.scrolled = false; }
      },
   },
   mounted: function () {
      window.addEventListener('scroll', this.scrollListener);
   },
   beforeDestroy: function () {
      window.removeEventListener('scroll', this.scrollListener);
   },
};
</script>
<style>

.v-color-picker__swatch {
   display: flex !important;
   flex-direction: row !important;
   margin-bottom: 0 !important;
   flex-wrap: wrap !important;
   align-content: center;
   justify-content: center;
}
.theme--dark.v-sheet {
   background-color: #012a5200 !important;
}
</style>
