<template>
   <div class="px-5 app-container v-sheet v-sheet--shaped mt-4">
      <v-btn color="primary" class="ma-4 mb-8 ml-0 pl-0" width="200" x-large @click="createItem">
         <span class="text-caption text-md-left font-weight-medium pl-3">
            Add a Category
         </span>
         <v-icon class="pl-16" right>
            mdi-plus-circle-outline
         </v-icon>
      </v-btn>
      <v-card rounded="2" width="1040px" >
         <v-row >
            <v-col md="10">
               <v-container class=" ma-2 pa-0">
                  <v-card rounded class="table-container" elevation="2">
                     <v-row class=" ml-2 mb-1 mt-1 font-weight-bold">
                        <v-col :cols="1"  class="text-center">
                        </v-col>
                        <v-col :cols="4" class="category-title">
                           Category Title
                        </v-col>
                        <v-col :cols="2" class="category-title">
                           Color
                        </v-col>
                        <v-col :cols="2" class="category-title ">
                           No of items
                        </v-col>
                        <v-col :cols="3" class="category-title">
                           Actions
                        </v-col>
                     </v-row>
                     <v-divider style="border-color:#cccccc;"></v-divider>
                     <v-list color="#fff"
                             v-scroll.self="onScroll"
                             class="overflow-y-auto overflow-x-hidden category-height"
                             >
                        <v-list-group prepend-icon="" active-class="activeIcon" sub-group v-for="cat in items" :value="false" :key="cat.id" color="blue">
                           <template v-slot:activator>
                              <v-row>
                                 <v-col :cols="1" class="">
                                    <v-icon class="expandedIcon" v-if="cat.children" small>mdi-chevron-down</v-icon>
                                    <v-icon v-if="!cat.children" small>mdi-minus</v-icon>
                                 </v-col>
                                 <v-col :cols="4" class="align-self-center">
                                    <span class="category-parent">{{ cat.title }}</span>
                                 </v-col>
                                 <v-col :cols="2" class="">
                                    <v-icon :color="cat.color" x-large>mdi-checkbox-blank-circle</v-icon>
                                 </v-col>
                                 <v-col :cols="2" class="text-center align-self-center pr-8">
                                    <span class="category-parent">{{ cat.children?cat.children.length:'0' }}</span>
                                 </v-col>
                                 <v-col :cols="3" class="">
                                    <v-btn plain text small color="secondary" class="ma-0 pa-1" @click.native.stop @click="editItem(cat)">
                                       <v-icon x-small>
                                          mdi-pencil-outline
                                       </v-icon>
                                       Edit
                                    </v-btn>
                                    <v-btn plain text small color="secondary" class="ma-0 pa-1" @click.native.stop @click="deleteItem(cat)">
                                       <v-icon x-small>
                                          mdi-close-circle-outline
                                       </v-icon>
                                       Remove
                                    </v-btn>
                                 </v-col>
                              </v-row>
                           </template>
                           <v-list-item
                              v-for="icon in cat.children"
                              :key="icon.id"
                              link
                              >
                              <v-row>
                                 <v-col :cols="1" class="">
                                    <v-icon x-small class="pl-5">mdi-subdirectory-arrow-right</v-icon>
                                 </v-col>
                                 <v-col  :cols="4" >
                                    <span class="category-parent">{{ icon.title }}</span>
                                 </v-col>
                                 <v-col :cols="2"  class=" d-flex ">
                                    <v-img :src="getIconWithColor(icon.icon, icon.color)" max-height="35" contain max-width="35"></v-img>
                                 </v-col>
                                 <v-col :offset="2" :cols="3" class="">
                                    <v-btn plain text small color="secondary" class="ma-0 pa-1" @click="editItem(icon)">
                                       <v-icon x-small>
                                          mdi-pencil-outline
                                       </v-icon>
                                       Edit
                                    </v-btn>
                                    <v-btn plain text small color="secondary" class="ma-0 pa-1" @click="deleteItem(icon)">
                                       <v-icon x-small>
                                          mdi-close-circle-outline
                                       </v-icon>
                                       Remove
                                    </v-btn>
                                 </v-col>
                              </v-row>
                           </v-list-item>
                        </v-list-group>
                     </v-list>
                  </v-card>
               </v-container>
            </v-col>
         </v-row>
      </v-card>
      <CategoryForm :item="formItem" :categoryItems="items" @update-value="updateData" />
   </div>
</template>

<script>
import Category from '@/models/Category.js';
import CategoryService from '@/service/CategoryService.js';
import CategoryForm from '@/components/CategoryForm.vue';

export default {
   components: {
      CategoryForm,
   },
   data: () => ({
      items: [],
      formItem: null,
   }),
   methods: {
      onScroll() {},
      updateData(data) {
         this.formItem = data;
         this.callServiceList();
      },
      getIconWithColor(filename, folder) {
         return `${process.env.VUE_APP_API}/svg/${folder.replace('#', '').toLowerCase()}/${filename}.svg`;
      },
      createItem() {
         this.formItem = new Category();
      },
      editItem(item) {
         this.formItem = item;
      },

      indexMethod(index) {
         return index + (this.page - 1) * 10 + 1;
      },
      sortChange(event) {
         this.sort = event.prop;
         this.order = event.order === 'ascending' ? 'asc' : 'desc';
         this.callServiceList();
      },
      callServiceList(page = 1) {
         this.page = page;
         this.loading = true;
         const categoryService = new CategoryService(this);
         categoryService.list().then((list) => {
            if (list) {
               this.items = list.data.data;
            }
            this.loading = false;
         });
      },
      doReset() {
         this.callServiceList();
      },
      getIcon(icon, color) {
         return `${process.env.VUE_APP_API}/api/utils/svg/${icon}/${color}`;
      },
      deleteItem(item) {
         if (confirm('you have chosen to delete this category, continue?')) {
            const categoryService = new CategoryService(this);
            categoryService.delete(item.id).then(() => {
               this.doReset();
            });
         }
      },
   },
   created() {
      this.doReset();
   },
};
</script>
<style>
.expandedIcon{
   transition: all 0.75s 0.25s;
   transform: rotate(0);
}
.activeIcon .expandedIcon{
   transform: rotate(180deg);
}

.theme--dark.v-card {
  background-color: transparent !important;
  border: none;
}

.category-parent {
   color: #000000 !important;
   font-size: 0.75rem;
   font-weight: 400;
   text-transform: capitalize;
}

.category-title {
   color: #000000 !important;
   font-size: 0.75rem;
   font-weight: 500;
   padding-top:1rem;
}
.table-container {
   background-color: #FFFFFF !important;

}

.bgColor {
   background-color: #f5f5f5 !important;
}

.category-height{
    height: calc(100vh - 250px);
}
::-webkit-scrollbar {
   width: 4px;
   height: 10px;
   margin-top: 0px;
 }
 ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
 }
 ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    border: 0px none #ffffff;
    border-radius: 1px;
    color:var(--v-bgoverlay-base)
 }
::-webkit-scrollbar-thumb:active {
   background: map-get($colors-alpha, 'bg-95');
 }
::-webkit-scrollbar-track {
   background: transparent;
   border: 0px none #ffffff;
   border-radius: 2px;
 }
 ::-webkit-scrollbar-track:hover {
   background: transparent;
 }
 ::-webkit-scrollbar-track:active {
   background: transparent;
 }
 ::-webkit-scrollbar-corner {
   background: transparent;
 }
 ::-webkit-scrollbar-track-piece:start {
     background: transparent;
     margin-top: 0px;
 }

</style>
