import ParentModel from '@/models/parent/ParentModel.js';

export default class Category extends ParentModel {
   constructor(data) {
      super(data);
      if (data != null) {
         this.id = data.id;
         this.parent_id = data.parent_id;
         this.title = data.title;
         this.color = data.color;
         this.icon = data.icon;
      } else {
         this.id = '';
         this.parent_id = 0;
         this.title = '';
         this.color = '';
         this.icon = '';
      }
   }

   get toDto() {
      const dto = {};
      dto.id = this.id;
      dto.parent_id = this.parent_id;
      dto.title = this.title;
      dto.color = this.color;
      dto.icon = this.icon;
      return dto;
   }
}
